<template>
  <div>
    <trac-loading v-if="loading" />
    <template v-else>
      <div class="flex flex-col gap-y-4 mb-12">
        <h2>Sales</h2>
        <h3 class="font-bold text-2xl uppercase">Pos</h3>
      </div>

      <div
        class="flex items-center justify-between gap-20 bg-white rounded-lg big-shadow px-12 py-32"
      >
        <trac-dropdown-exteneded
          :neededProperty="'name'"
          placement="left"
          :options="stores"
          @optionSelected="setStore($event)"
          class="mb-2 md:mb-0"
        >
        </trac-dropdown-exteneded>
        <div class="flex-column w-full md:w-1/4">
          <trac-button
            :disabled="!store"
            @button-clicked="gotoPOSDashboard"
            class="uppercase w-full mt-2 md:mt-0"
          >
            Proceed
          </trac-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import {
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";

export default {
  name: "SelectStore",
  data() {
    return {
      store: "",
      stores: [],
      loading: false,
    };
  },
  async created() {
    // this.stores = this.getAssignedStoresFromSession().map((item) => {
    //   return {
    //     _id: item.id,
    //     name: item.name,
    //   };
    // });
    this.fetchAllStores();
  },
  methods: {
    getAssignedStoresFromSession() {
      const sessionData = GET_LOCAL_DB_DATA("traction-app-user-data");
      const stores =
        (sessionData || { user: { stores: [] } }).user.stores || [];
      // this.stores = stores
      return stores;
    },
    async fetchAllStores() {
      this.loading = true;
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];
      if (res.status) {
        this.stores = res.data;
        if (this.stores.length === 1) {
          this.store = this.stores[0];
          this.gotoPOSDashboard();
        }
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.loading = false;
    },
    setStore(store) {
      this.store = store;
    },
    gotoPOSDashboard() {
      if (this.store) {
        SAVE_LOCAL_DB_DATA("pos-sales-store", this.store);
        SAVE_LOCAL_DB_DATA("pos-sales-data", null);
        this.$router.push({ name: "PosDashboard" });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
